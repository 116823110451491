@import 'antd/dist/reset.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}



#evaluation {
  background-color: initial;
  max-width: initial;
  width: 40%;
  flex: initial;
  min-width: 200px;
}

.ant-layout-header {
  padding-left: 6px;
}

@media (max-width: 767px) {
  .mobile {
    display: initial !important;
  }

  .mobile-flex {
    display: flex !important;
  }

  .desktop {
    display: none !important;
  }
}


@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }

  .mobile-flex {
    display: none !important;
  }

  .desktop {
    display: initial !important;
  }
}

.clear-local-storage-and-cookie {
  position: absolute;
  width: 100%;
  bottom: 10px;
  text-align: center;
  display: none;
}

@media screen and (min-width: 992px) {
  .desktop-only {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .mobile-only {
    display: block;
  }
}

.ant-tabs-content-holder {
  height: 100% !important;
}

.ant-tabs-content {
  height: 100% !important;
}

.bottom-menu .ant-menu-item-icon{
  display: block!important;
  font-size: 18px!important;
}

.bottom-menu .ant-menu-title-content{
  display: block;
  line-height: 30px;
  font-size: 12px;
  margin-inline-start: 0px!important;
}

.bottom-menu .ant-menu-item{
  padding-top: 6px;
  width: 60px!important;
  flex: 1 1 0!important;
}


.para .persona {
  border-bottom: solid 3px #eea9a8;
  cursor: pointer;
  border-radius: 3px;
}

.para .persona:hover {
  background-color: antiquewhite;
}

.para .arrange {
  border-bottom: solid 3px #f2e338;
  cursor: pointer;
  border-radius: 3px;
}

.para .arrange:hover {
  background-color: lightyellow;
}

.para .metaphor {
  border-bottom: solid 3px #a7b4ff;
  cursor: pointer;
  border-radius: 3px;
}

.para .metaphor:hover {
  background-color: lightblue;
}

.para-index .ant-badge-count {
  margin-bottom: 6px;
  margin-right: 6px;
  border: solid 2px darkorange;
  font-weight: bold;
  line-height: 16px;
  background-color: #fff;
  color: darkorange;
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.card-border {
  border-radius: 1em;
  border: 4px solid lightgrey;
}

.card-container {
  width: 100%;
  padding: 0.5em;
  position: relative;
}

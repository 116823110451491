.wrapper-class {
  overflow: auto;
  height: 500px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 10px;
}

.editor-class {
  width: 100%;
  height: 100%;
}

.text-recognition .ant-upload.ant-upload-drag .ant-upload {
  padding: 0 !important
}

.small-imgs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.small-imgs img {
  width: 100%;
  height: 100%;
  position: relative;
}

.small-imgs img:hover {
  cursor: pointer;
}

.small-imgs .active {
  border: 1px solid #ff5000;
}


.small-imgs .ant-col-5 {
  height: 100px;
  width: 20% !important;
  max-width: 20% !important;
  margin-top: 10px;
  position: relative;
}

.small-imgs .delete-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
}

.small-imgs .delete-icon:hover {
  color: #ff5000;
  cursor: pointer;
}